.footer {
  color: white;
  background-color: black;
}

.footer__img {
  position: relative;
}

.footer__img__bg {
  width: 100vw;
}

.footer__img__txt {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  margin: 0 auto;
  width: 50vw;
  transform: translate(-50%, 5rem);
}

.footer__content {
  padding: 7rem 0;
  text-align: center;
}

.footer__icon {
  width: 5rem;
  margin: 0 auto;
}

.footer__links {
  margin: 0 auto;
  width: 50vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}

.footer__links a {
  color: white;
  text-decoration: none;
  font-weight: 700;
}

.footer__content p {
  padding-top: 4rem;
}

.footer__form {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.footer__logo {
  margin-top: 10rem;
  margin-bottom: 6rem;
  width: min(50vw, 180px);
}

.footer__copy {
  text-align: left;
  margin-top: 8rem;
  padding: 0 8vw;
}

.footer__copy h3 {
  font-size: xx-large;
  font-weight: 800;
  margin: 0;
}

.footer__copy p {
  padding: 0;
}

.footer__icons a {
  color: white;
  font-size: x-large;
  padding-right: 1rem;
  transition: 0.2s;
}

.footer__icons a:hover {
  color: #fffb;
}

.footer__info {
  width: 70vw;
  margin: 0 auto;
  margin-top: 5rem;
}

.footer__info a {
  color: white;
  text-decoration: none;
  font-weight: 700;
}

/* --- SMALL FOOTER --- */
.footer__minimal .footer__content {
  padding: 5rem 0;
  text-align: center;
  width: clamp(40%, 500px, 90%);
  margin: 0 auto;
}

.footer__minimal h4 {
  font-family: 'Knewave', cursive;
  font-size: 2vw;
  font-weight: 500;
  margin: 0;
}

.footer__minimal p {
  padding-top: 1rem;
}

/* --- TABLET --- */
@media only screen and (max-width: 830px) {
  .footer__img__bg {
    width: 120vw;
    margin-left: -10vw;
  }

  .footer__img__txt {
    transform: translate(-50%, 2rem);
  }
}

/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
  .footer__img__bg {
    width: 180vw;
    margin-left: -40vw;
  }

  .footer__img__txt {
    width: 80vw;
    transform: translate(-50%, 1rem);
  }

  .footer__icon {
    width: 4rem;
    margin-top: -5rem;
  }

  .footer__links {
    flex-direction: column;
  }

  .footer__links a {
    margin-bottom: 1rem;
  }

  .footer__content p {
    padding-top: 3rem;
    padding: 0 5vw;
  }

  .footer__logo {
    margin-top: 8rem;
    margin-bottom: 1rem;
  }

  .footer__copy {
    padding: 0 10vw;
  }

  .footer__copy p {
    padding: 0;
  }

  .footer__icons a {
    font-size: xx-large;
  }
}
