.video {
    background-color: #170329;
    color: white;
    padding-top: 22vh;
    margin-top: -8px;
}

.video h3 {
    font-family: 'Knewave', cursive;
    text-align: center;
    font-size: 4vw;
}

.video p {
    text-align: center;
    width: 55vw;
    margin: 2rem auto;
    font-size: large;
}

.video__button {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    margin-bottom: 8rem;
}

.video__preview {
    display: block;
    width: 70vw;
    margin: 0 auto;
    cursor: pointer;
    margin-bottom: 5rem;
}

/* --- TABLET --- */
@media only screen and (max-width: 830px) {
    .video {
        padding-top: 10vh;
    }
    
    .video p {
        width: 70vw;
    }

    .video__preview {
        width: 80vw;
    }
}


/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
    .video {
        padding: 0 1rem;
        padding-top: 5vh;
    }

    .video h3 {
        font-size: x-large;
    }

    .video__preview {
        width: 100vw;
        margin-left: -1rem;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .video__button {
        margin-bottom: 3rem;
    }
}