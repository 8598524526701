.button__container {
  display: flex;
  align-items: center;
  width: fit-content;
}

.button {
  text-decoration: none;
  border: 0;
  padding: 0.6rem 2.2rem;
  font-size: 10pt;
  font-weight: bold;
  border-radius: 1.4rem;
  position: absolute;
  transform: translate(-7px, -4px);
  transition: 0.3s; 
  white-space: nowrap;
}

.button:hover {
  transform: translate(0, 0);
  cursor: pointer;
}

.button__shadow {
  text-decoration: none;
  background: linear-gradient(
    90deg,
    rgba(98, 166, 252, 1) 0%,
    rgba(219, 129, 255, 1) 100%
  );
  color: black;
  padding: 0.6rem 2.2rem;
  font-size: 10pt;
  font-weight: bold;
  border-radius: 1.4rem;
  white-space: nowrap;
}
