.how__video {
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    top: 0;
    z-index: -1;
}

.how__button {
    position: absolute;
    left: 50%;
    top: 100vh;
    transform: translate(-50%, calc(-100% - 5rem));
}

.how__container {
    background-color: white;
    text-align: center;
}

.how__container h3 {
    font-family: 'Knewave', cursive;
    font-size: 4vw;
    font-weight: 500;
    margin: 0;
}

.how__container p {
    font-size: larger;
    font-weight: 500;
    margin: 1.6rem auto;
    width: clamp(40%, 400px, 90%);
}

.how__text {
    padding: 4rem 0;
}

.how__text a {
    color: var(--main);
    text-decoration: none;
    font-weight: 600;
}

.how__text h4 {
    font-family: 'Knewave', cursive;
    font-size: 22pt;
    font-weight: 500;
    margin: 0;
    margin-top: 4rem;
}