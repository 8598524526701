@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Knewave&display=swap');

html, body {
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}
section {
  overflow: hidden;
}
:root {
  --main: #FF00FF;
}

body {
  position: relative;
  margin: 0;
}

* {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bold {
  font-weight: bold;
}

/* --- MOBILE --- */
@media only screen and (max-width: 830px) {
  .no_mobile {
    display: none;
  }
  p {
    font-size: large;
  }
}
/* ---  SCROLLBAR  --- */
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #FFF; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom,#FFBFFF, #BFF4FF); 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom,#ffa4ff, #a8f0ff); 
}