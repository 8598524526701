.contact {
    text-align: center;
    padding: 5rem 0;
}

.contact h3 {
    font-family: 'Knewave', cursive;
    font-size: 3vw;
    font-weight: 500;
    margin: 0;
}

.contact__form {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: clamp(30%, 400px, 90%);
    margin: 0 auto;
    margin-top: 2rem;
}

.contact__form label {
    font-weight: 600;
    margin: 1rem 0;
}

.contact__form input, .contact__form textarea {
    background-color: #F5F5F5;
    border: 0;
    color: black;
    padding: .6rem 1.2rem;
    border-radius: .6rem;
    font-size: medium;
    font-weight: 500;
    outline: none;
}

.contact__form textarea {
    padding: 1.2rem;
    resize: none;
}

.contact__form .button__container {
    margin: 0 auto;
    margin-top: 2rem;
}