.about__video {
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    top: 0;
    z-index: -1;
}

.about__scroll {
    position: absolute;
    top: 90vh;
    left: 2rem;
    font-family: 'Knewave', cursive;
    font-size: x-large;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.about__scroll i {
    font-size: 26pt;
    margin-left: 1rem;
    margin-top: .4rem;
    transition: .3s;
}

.about__scroll:hover >  i {
    transform: translate(0, 10px);
}

.about__container {
    padding: 5rem 0;
    background-color: white;
    text-align: center;
}

.about__container h3 {
    font-family: 'Knewave', cursive;
    font-size: 4vw;
    font-weight: 500;
    margin: 0;
}

.about__container p {
    font-size: larger;
    font-weight: 500;
    margin: 1.6rem auto;
    width: clamp(40%, 400px, 90%);
}