.page404 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page404 h1 {
  font-weight: 800;
  font-size: clamp(8vw, 42pt, 18vw);
  padding: 0;
  margin: 0;
}

.page404 h3 {
  padding: 0;
  margin: 0;
  margin-bottom: 4rem;
}

.page404__video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
}
