.features {
    text-align: center;
    padding: 6rem 0;
}

.features h2 {
    font-family: 'Knewave', cursive;
    font-size: 62pt;
    font-weight: 500;
    margin: 0;
    transform: rotate(-8deg);
}

.features h2 span {
    font-family: 'Knewave', cursive;
}

.features h2 span::before {
    content: 'JUST NU!';
    color: var(--main);
    font-family: 'Knewave', cursive;
    position: absolute;
    transform: translate(-5px, -5px);
}

.features__container {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.features__column {
    margin: 0 1.6rem;
}

.features h3 {
    font-family: 'Knewave', cursive;
    font-size: 22pt;
    font-weight: 500;
    margin-top: 9rem;
}

.sponsors {
    width: clamp(70vw, 800px, 90vw);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sponsor__container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sponsor__container img {
    width: 12vw;
    padding: 0 1rem;
}

.features br {
    display: none;
}    

/* --- TABLET --- */
@media only screen and (max-width: 830px) {
    .features__container {
        flex-direction: column;
    }

    .features h2 {
        font-size: 52pt;
    }
}

/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
    .features h2 {
        font-size: 52pt;
        margin: 0;
    }

    .features br {
        display: inline;
    } 

    .features__container {
        margin-left: 7vw;
    }   

    .features h3 {
        font-family: 'Knewave', cursive;
        font-size: 20pt;
        font-weight: 500;
        margin-top: 9rem;
    }

    .sponsors {
        flex-direction: column;
    }

    .sponsor__container img {
        width: 25vw;
        padding: 0 1rem;
    }
}