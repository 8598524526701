.title h1 {
    font-family: 'Knewave', cursive;
    margin: 0;
    position: absolute;
}

.title__shadow {
    color: var(--main);
}

.title__main {
    transform: translate(-4px, -3px);
}