.feature__item__container {
    position: relative;
    width: clamp(30vw, 410px, 85vw);
    text-align: left;
    margin-top: 5rem;
}

.feature__item {
    padding: 1.6rem 2.2rem;
    border-radius: 1.4rem;
    transform: translate(-10px, -10px);
    transition: 0.3s;
}

.feature__item:hover {
    transform: translate(0,0);
}

.feature__item__shadow {
    position: absolute;
    background: linear-gradient(
        5deg,
        #F8C8FC 0%,
        #BEEAFB 100%
      );
    width: 100%;
    height: 100%;
    border-radius: 1.4rem;
}

.feature__item h4 {
    font-family: 'Knewave', cursive;
    margin: 0;
    font-size: 26pt;
}

.feature__item__title___white {
    transform: rotate(-6deg) 
        translate(-3rem, -3.8rem);
}
.feature__item__title {
    position: relative;
}
.feature__item__title___black {
    width: 100%;
    position: relative;
    transform: rotate(-6deg)
        translate(-13px, -15px);
}
.feature__item__title___shadow {
    width: 100%;
    position: absolute;
    color: var(--main);
    transform: rotate(-6deg)
    translate(-10px, -12px);
}

.feature__item a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    font-weight: bold;
}
.feature__item a img {
    height: 12px;
    width: auto;
    margin: 0;
    padding-left: 1rem;
    transition: 0.3s;
}

.feature__item:hover > a img {
    transform: translate(10px, 0);
}