.press {
    text-align: center;
    padding: 5rem 0;
    width: clamp(50%, 550px, 90%);
    margin: 0 auto;
}

.press h4 {
    font-family: 'Knewave', cursive;
    font-size: 2vw;
    font-weight: 500;
    margin: 0;
}

.press p {
    font-size: larger;
}

.press a {
    text-decoration: none;
    font-weight: 600;
    color: var(--main);
}