.party img {
    width: 100vw;
}

.party .title {
    position: absolute;
    width: 65vw;
    transform: rotate(-13deg)
        translate(0, 15vw);
}

.party .title h1 {
    font-size: 5.6vw;
}

/* --- TABLET --- */
@media only screen and (max-width: 830px) {
    .party img {
        width: 140vw;
        margin-left: -20vw;
    }

    .party .title {
        position: absolute;
        width: 80vw;
        transform: rotate(-13deg)
            translate(5vw, 15vw);
    }

    .party .title h1 {
        font-size: 8vw;
    }
}

/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
    .party img {
        width: 200vw;
        margin-left: -50vw;
    }
    .party .title {
        position: absolute;
        width: 90vw;
        transform: rotate(-13deg)
            translate(2vw, 5vh);
    }

    .party .title h1 {
        font-size: 6vh;
    }
}