.header {
    display: flex;
    padding: 1.6rem 3.2rem;
    justify-content: space-between;
    align-items: center;
}

.header img {
    height: 2.2rem;
}

.header__links {
    display: flex;
    align-items: center;
}

.header__links a {
    text-decoration: none;
    color: white;
    margin: 0 3.6rem;
    font-weight: bold;
}

.header__links a:hover {
    color: black;
}

.header__black .header__links a {
    color: black;
}

.header__black .header__links a:hover {
    color: #555;
}

.header__menu__button {
    display: none;
}

.header__menu {
    background-color: white;
    color: black;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 100;
}

.header__menu__top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem 3.2rem;
}

.header__menu__links {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;
}

.header__menu__links a {
    text-decoration: none;
    color: black;
    margin: 14px 0;
    font-weight: bold;
    font-size: large;
}

.header__menu__links .button__container {
    margin: 3rem 0;
    margin-left: 7px;
}

/* --- TABLET --- */
@media only screen and (max-width: 830px) {
    .header__links a {
        margin: 0 1rem;
    }
}

/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
    .header {
        justify-content: center;
    }
    .header__links {
        display: none;
    }
    .header__button {
        display: none !important;
    }
    .header__menu__button {
        display: block;
        color: white;
        font-size: 32px;
        position: absolute;
        right: 1.8rem;
        top: 1.6rem;
        cursor: pointer;
    }
    .header__black .header__menu__button {
        color: black;
    }
    .header__menu__close {
        color: black;
    }
}