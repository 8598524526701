.app__container {
    position: relative;
}

.app__bg {
    width: 100vw;
}

.app__top__gradient {
    position: absolute;
    left: 0;
    width: 100vw;
    height: 18vh;
    background: linear-gradient(180deg, #200054 0%, #20005455 50%, #20005400 100%);
}

.app__text {
    position: absolute;
    z-index: 1;
    bottom: 10rem;
    left: 10vw;
    width: 52vw;
}

.app__info__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.app__info__container img {
    width: 45vw;
    transform: translate(-2vw, -18vh);
}

.app__info {
    width: 38vw;
    margin-left: 0;
}

.app h3 {
    font-family: 'Knewave', cursive;
    font-size: 4vw;
    font-weight: 500;
    margin: 1.4rem 0;
}

/* --- TABLET --- */
@media only screen and (max-width: 830px) {
    .app__bg {
        width: 140vw;
        margin-left: -20vw;
    }

    .app__text {
        width: 80vw;
        bottom: 3rem;
    }

    .app__info__container {
        flex-direction: column;
    }

    .app__info__container img {
        width: 60vw;
        transform: translate(-6vw, -12vw);
    }

    .app__info {
        margin-top: -5rem;
        margin-bottom: 3rem;
        width: 70vw;
        margin-left: 0;
    }
}

/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
    .app__bg {
        width: 180vw;
        margin-left: -40vw;
    }

    .app__top__gradient {
        height: 35vh;
    }

    .app__text {
        width: 90vw;
        margin-left: -5vw;
    }

    .app__info__container img {
        width: 75vw;
        transform: translate(0, -20vw);
    }

    .app h3 {
        font-size: x-large;
    }
}