.faq {
    background-color: #1F1F1F;
    color: white;
    text-align: center;
    padding: 6rem 0;
}

.faq h3 {
    font-family: 'Knewave', cursive;
    font-size: 4vw;
    font-weight: 500;
    margin: 0;
}

.faq__questions {
    width: min(550px, 90%);
    margin: 5rem auto;
    text-align: left;
}

.faq__question {
    border-bottom: 1px solid white;
}

.faq__question h4 {
    font-size: 16pt;
    font-weight: bold;
    padding: 0;
    padding-left: 2rem;
    cursor: pointer;
    user-select: none;
}

.faq__question h4:hover {
    text-decoration: underline;
    color: #FFFC;
}

.faq__question h4::before {
    content: '+ ';
}

.faq p {
    margin: 1.6rem auto;
}

.faq__button {
    margin: 0 auto;
}

.faq__answer {
    padding-left: 4rem;
}

/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
    .faq h3 {
        font-size: x-large;
    }
}