.landing__container {
  background: url('./background.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.landing {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landing__mockup__container {
    width: 45vw;
}

.landing__mockup {
    width: 40vw;
    margin-left: 7vw;
    margin-top: 5vh;
}

.landing__title__container img {
    width: 55vw;
    position: absolute;
    top: 0;
    right: -8vw;
    top: 25vh;
}

.landing__subtitle {
    font-family: 'Knewave', cursive;
    color: white;
    font-size: x-large;
    text-align: center;
    position: absolute;
    width: 45vw;
    right: 0;
    transform: rotate(-8deg);
}

.landing__subtitle span {
    font-family: 'Knewave', cursive;
    color: var(--main);
}

.landing__title__form {
    position: absolute;
    width: 45vw;
    right: 0;
    margin-top: 20vh;
}

.landing__title__form p {
    color: white;
}

/* --- TABLET --- */
@media only screen and (max-width: 830px) {
    .landing__title__container img {
        width: 120vw;
        position: absolute;
        top: 0;
        right: -30vw;
        top: 25vh;
    }

    .landing__subtitle {
        font-size: xx-large;
        text-align: center;
        position: absolute;
        width: 80vw;
        right: 10vw;
        top: 70vw;
        transform: rotate(-8deg);
    }

    .landing__title__form {
        position: absolute;
        width: 80vw;
        right: 50%;
        margin-top: 70vh;
        transform: translate(60%, 0);
    }
}

/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
    .landing__subtitle {
        font-size: x-large;
        top: 42vh;
    }

    .landing__subtitle br {
        display: none;
    }

    .landing__title__form {
        position: absolute;
        width: 90vw;
        right: 5vw;
        margin-top: 55vh;
        transform: translate(0,0);
    }
}