.preview {
  background-color: #200054;
  color: white;
  padding-top: 5rem;
}

.preview h3 {
  font-family: 'Knewave', cursive;
  text-align: center;
  font-size: 4vw;
  margin: 0;
  margin-top: 1rem;
}

.preview__container p {
  text-align: center;
  width: 40vw;
  margin: 0 auto;
  margin-top: 3vh;
}

.preview__container {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview__container img {
  width: 38vw;
}

.preview__container ul {
  width: 35vw;
  font-weight: bold;
  font-size: x-large;

  list-style: none;
}

.preview__container ul li {
  padding: 1rem 0;
}

.preview__container ul li:before {
  content: '✓';
  margin-right: 1rem;
  font-size: xx-large;
}
.preview__elements,
.preview__element {
  display: flex;
  align-items: top;
  justify-content: center;
}

.preview__elements {
  align-items: center;
}

.preview__elements h3,
.preview__element div {
  width: 25vw;
  overflow: hidden;
  margin: 0 2vw;
}

.preview__elements h4 {
  font-weight: 600;
  font-size: x-large;
  margin-bottom: 0.6rem;
}

.preview__elements p {
  font-size: large;
  margin: 0;
  min-height: 88px;
}

/* --- TABLET --- */
@media only screen and (max-width: 830px) {
  .preview__elements {
    flex-direction: column;
  }

  .preview__elements h3 {
    width: 90vw;
    margin: 0;
    font-size: xx-large;
  }

  .preview__element div {
    width: 40vw;
    margin: 0 auto;
    text-align: center;
  }
}

/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
  .preview {
    padding: 0 1rem;
    padding-top: 5vh;
  }

  .preview h3 {
    font-size: xx-large;
  }

  .preview__container img {
    display: none;
  }

  .preview__container ul {
    width: 70vw;
    font-size: larger;
  }

  .preview__element {
    flex-direction: column;
  }

  .preview__elements div {
    width: 90vw;
    margin: 0 auto;
    text-align: center;
  }

  .preview__elements p {
    width: 100%;
  }
}
