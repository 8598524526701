.social {
    background-color: black;
    color: white;
    text-align: center;
    padding: 6rem 0;
}

.social h3 {
    font-family: 'Knewave', cursive;
    font-size: 4vw;
    font-weight: 500;
    margin: 0;
    margin-top: 3rem;
}

.social__icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social__icons a {
    color: white;
    text-decoration: none;
    font-size: 32pt;
    margin: 0 2.6rem;
    margin-top: 4rem;
}

/* --- MOBILE --- */
@media only screen and (max-width: 400px) {
    .social h3 {
        font-size: xx-large;
        margin-top: 1rem;
    }

    .social__icons {
        justify-content: space-evenly;
    }
    
    .social__icons a {
        margin: 0;
        font-size: 28pt;
        margin-top: 3rem;
    }
}