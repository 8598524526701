.login__container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 1.4rem;
}

.login__container input {
    border: 0;
    margin-left: 1.2rem;
    outline: none;
    width: clamp(12vw, 300px, 40vw);
    font-size: medium;
}
